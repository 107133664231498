.imageIs {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: -10;

}
.imageIs img {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    height: 75%;
    width: 40%;
    object-fit: cover;
    opacity: 30%;
}


.textsIs{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.textsIs .logoIs{
    width: 100vw;
    display: flex;
    justify-content: center;
}
.textsIs img{
    height: 200px;
}

.textsIs .link{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}
.buttonIs{
    width: 100%;
    display: flex;
    justify-content: center;
}

.textsIs a{
    display: flex;
    justify-content: center;
    padding-inline: 30px;
    border-radius: 2px;
    text-decoration: none;
    padding-block: 10px;
    border-radius: 10px;
    background-color: #737373;
}