/*------ MAX SCREEEN RESPONSIVE ------*/

.service h1{
    width: 100vw;
    justify-content: end;
    display: flex;
    text-align: end;
    font-family: 'Yanone Kaffeesatz';
}

.bA{
    display: flex;
    justify-content: center;
    align-items: center;
}

.service .textBefore{
    display: flex;
    justify-content: start;
    padding-right: 20px;
    width: 100%;
    padding-top: 10px
}

.service .before{
    height: 400px;
    width: 360px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    opacity: 35%;
    overflow: hidden;
    transition: all .5s;
}
.service .before:hover{
    opacity: 100%;
    overflow: hidden;
    transition: all .5s;
}


.service .textAfter{
    display: flex;
    justify-content: end;
    align-items: flex-end;
    padding-top: 10px;
    padding-left: 20px;
    width: 100%;
}

.service .after{
    height: 400px;
    width: 600px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: end;
    align-items: flex-end;
}

.service .after img{
    position: relative;
    width: 600px;
    animation: beforeAnim 25s infinite;
    opacity: 100%;
}

.service .divService{
    width: 100vw;
    display: flex;
    padding: 50px;
}




.service .divAf{
    margin-left: 50px;
}
.service .divBef:hover{
    opacity: 100%;
    transition: all .5s;

}




@keyframes  beforeAnim{
    0%{
      top: 0%;
    }
    
    50%{
        top: 90%;
      }

    100%{
        top: 0%;
      }
  }



#caroulselServ{
    display: flex;
}

.bAFlex{
    justify-content: center;
    display: flex;
}

.gradientBef{
    position: absolute;
    background-image: linear-gradient(300deg, rgba(0, 0, 0, 0) , rgb(9, 9, 9));
    z-index: 10;
    height: 500px;
    width: 710px;
    transition: all 1s;
}
.gradientBef:hover{
    transition: all 1s;
}

.gradientAf{
    position: absolute;
    background-image: linear-gradient(300deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0),rgba(0, 0, 0, 0), rgb(9, 9, 9));
    z-index: 10;
    height: 500px;
    width: 360px;
}




/*------ TABLET RESPONSIVE ------*/

@media (max-width: 1200px) {
    .service .after{
        width: 300px;
        height: 400px;
        border-bottom-right-radius: 50px;
    }
    .service .before{
        width: 300px;
        height: 400px;
        border-top-left-radius: 50px;
    }
    .service .after img{
        animation: none;
        width: 300px;
        height: 400px;
    }
    .gradientBef{
        display: none;
    }
    
  }


/*------ LARGE SMARTPHONE RESPONSIVE ------*/

@media (max-width: 800px) {
    .service .after{
        width: 300px;
        height: 400px;
        border-bottom-right-radius: 50px;
    }
    .service .before{
        width: 300px;
        height: 400px;
        border-top-left-radius: 50px;
    }
    .service .after img{
        animation: none;
        width: 300px;
        height: 400px;
    }
    .gradientBef{
        display: none;
    }


  }




  
/*------ MEDIUM SMARTPHONE RESPONSIVE ------*/

@media (max-width: 790px) {
    .service .after{
        margin-top: 20px;
        margin-left: 20px;
        width: 190px;
        height: 260px;
        border-bottom-right-radius: 20px;
    }
    .service .before{
        margin-top: 50px;
        margin-left: -20px;
        width: 190px;
        height: 260px;
        border-top-left-radius: 20px;
    }
    .service .after img{
        object-fit: cover;
        animation: none;
        width: 190px;
        height: 260px;
    }
    .service .before img{
        object-fit: cover;
        width: 190px;
        height: 260px;
        border-top-left-radius: 30px;
    }
    .gradientBef{
        display: none;
    }
    .bAFlex{
        width: auto;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .service .divAf{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 0px;
        flex-direction: column;
        align-items: center;
    }
    .service .divBef{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 0px;
        flex-direction: column;
        align-items: center;
    }
    .service h1{
        width: 100vw;
        justify-content: start;
        display: flex;
        text-align: start;
        padding: 20px;
        font-family: 'Yanone Kaffeesatz';
        
    }
    .service .textBefore{
        display: flex;
        justify-content: center;
        padding-right: 0px;
        width: 100%;
        padding-top: 10px;
        margin-right: 20px;
    }
    
.service .textAfter{
    display: flex;
    justify-content:center;
    align-items: center;
    padding-top: 10px;
    padding-left: 20px;
    width: 100%;
    margin-left: 20px;
}
}