.about{
    width: 100%;
    padding-inline: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about h1{
    font-family: 'Yanone Kaffeesatz';
    font-size: 75px;
    width: 100%;
    padding-left: 0px;
}
.about h3{
    width: 50%;
    text-align: start;
}
.about .desc{
    width: 100%;
    display: flex;
    justify-content: start;
}
.about .images{
    width: 1000px;
    margin-top: 50px;
    height: 500px;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}
.about .images img{
    opacity: 60%;
    display: flex;
    height: 500px;
}




/*------ TABLET RESPONSIVE ------*/

@media (max-width: 1050px) {
    .about .images{
        width: 800px;
        height: 400px;
        
    }
    .about .images img{
        height: 400px;
        object-fit: cover;
    }
  }
  



/*------ LARGE SMARTPHONE RESPONSIVE ------*/

@media (max-width: 800px) {
    .about .images{
        width: 700px;
        height: 400px;
        
    }
    .about .images img{
        height: 350px;
        object-fit: cover;
    }
  }


/*------ LARGE SMARTPHONE RESPONSIVE ------*/

@media (max-width: 500px) {
    .about{
        width: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .about h1{
        padding-left: 10px;
    }
    .about h3{
        padding-inline: 10px;
        width: 100%;
        text-align: start;
    }
    .about .images{
        margin-top: 0px;
        width:100%;
    }
    .about .images img{
        height: 100%;
        object-fit: cover;
    }
  }


  /*------ SMALL SMARTPHONE RESPONSIVE ------*/
  
  @media (max-width: 300px) {
    
  }