h1{
  font-family: 'Montserrat', sans-serif;
  color: #F2F2F2;
}
.walpapper{
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: -1;
  object-fit:cover;
  object-position: center;
  animation: opcImg 30s infinite;
}
.walpapper img{
  animation: zoomImg 30s infinite;
}
@keyframes zoomImg{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.3);
  }
  100%{
    transform: scale(1);
  }
}
@keyframes opcImg{
  0%{
    opacity: 20%;
  }
  50%{
    opacity: 15%;
  }
  90%{
    opacity: 30%; 
  }
  100%{
    opacity: 20%;
  }
}
.homePage{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  color: White;
}
.homePage .title{
  height: 100%;
  width: 100vw;
  justify-content: center;
  display: flex;
  align-items: center;
}
.homePage .menu{
  display: none;
}
.menuActive a{
  cursor: pointer;
  margin-left: 20px;
  text-decoration: none;

}
.title img{
  height: 400px;
}
.menuActive{
  display: flex;
  position: fixed;
  width: 100vw;
  background-color: #737373;
  height: 65px;
  align-items: center;
  padding-left: 50px;
  font-family: 'Yanone Kaffeesatz';
  font-size: 30px;
  z-index: 10;
  transition: all 1s;
  
}
.menuActive::before{
  transition: all 1s;
}





/*------ TABLET RESPONSIVE ------*/

@media (max-width: 1050px) {
  .title img{
    height: 300px;
  }
  .menuActive{
    height: 50px;
    width: 80%;
    margin-top: 20px;
    padding: 20px;
    border-radius: 50px;
  }
  .menuActive a{
    font-size: 20px;
  }
}



/*------ MEDIUM SMARTPHONE RESPONSIVE ------*/

@media (max-width: 500px) {
  .title img{
    height: 260px;
 
  }
  .walpapper img{
    animation: leftImg 20s infinite;
    opacity: 5%;
    position: relative;
  }
  .menuActive{
    display: none;
  }
  @keyframes leftImg{
    0%{
      opacity: 80%;
      left: 0%;
    }
    50%{
      opacity: 100%;
      left: -100%;
    }
    90%{
      opacity: 20%;
      left: 100%;
    }
    100%{
      opacity: 80%;
      left: 0%;
    }
  }
}

@media (max-width: 290px) {
  .title img{
    height: 200px;
  }
}