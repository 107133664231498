.galery {
    height: 100%;
    width: 100vw;
}
.galery h2{
    width: 100vw;
    display: flex;
    font-family: 'Yanone Kaffeesatz';
    font-size: 50px;
    justify-content: center;
    padding: 10px;
    font-weight: 200;
    flex-direction: column;
    align-items: center;
}
.galery h2 img{
    padding: 0px;
    height: 100px;
}
.galery .divGalery1{
    margin-top: 20px;
}
.galery h4{
    font-weight: 500;
    margin-top: 20px;
    padding-inline: 10px;
}
.divGalery1 div img {
    width: 100vw;
    height: 50vh;
    object-fit: cover;
    animation: zoomImg 30s infinite;
}

.galery .divGalery2 {
    margin-top: 5px;
}

.galery .divGalery2 div {
    width: 50vw;
}

.divGalery2 div img {
    height: 100px;
    object-fit: cover;
    border-radius: 15px;

}

@keyframes zoomImg{
    0%{
      opacity: 60%;
      transform: scale(1);
    }
    50%{
      opacity: 80%;
      transform: scale(1.3);
    }
    90%{
      opacity: 90%;
    }
    100%{
      opacity: 60%;
      transform: scale(1);
    }
  }

.containGaleryMin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
}



@media (max-width: 1050px) {
    .galery {
        height: 100%;
        width: 100vw;
    }

    .galery .divGalery1 div {
        width: 100vw;
    }

    .divGalery1 div img {
        height: min-content;
        object-fit: cover;
        animation: none;
        display: flex;
        height: 30%;
    }

    .galery .divGalery2 {
        margin-top: 5px;
    }

    .galery .divGalery2 div {
        width: 80vw;
    }

    .divGalery2 div img {
        border-radius: 30px;
        height: 100px;
        object-fit: cover;
        transform: scale(0.5);
    }

    .containGaleryMin {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;
    }

}