/* ---------- CSS Presets ------------- */

#root{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  /* height: 1px; */
  width: 3px;
}
*::-webkit-scrollbar-thumb {
  background-color: #737373;
  border-radius: 15px;
}
*::-webkit-scrollbar-track {
  background-color: #1f1d1d;
}
body {
  background-color: #090909;
  width: 100vw;
  overflow-x: hidden;
}
h1{
  color: #F2F2F2;
  padding: 100px;
  font-family: 'Yanone Kaffeesatz';
  font-size: 75px;
}
h2{
  font-size: 45px;
  font-weight: 600;
  color: #F2F2F2;
  font-family: 'Montserrat', sans-serif;
}
h3{
  color: #F2F2F2;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 500;
}
h4{
  color: #F2F2F2;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 200;
}
h5{
  color: #f2f2f246;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 100;
}

a{
  font-weight: 600;
  color: #F2F2F2;
  font-family: 'Montserrat', sans-serif;
}